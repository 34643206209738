import { of as observableOf, Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { BuyPromise } from '@app/models/buy-promise';
import { PurchaseOrder } from '@app/models/shopping-cart';
import { StorageService } from '@app/services/storage/storage.service';
import { map } from 'rxjs/operators';
import { Costing, Range } from '@app/models/costing';
import { Container } from '@app/models/container';
import { GoogleTagManagerService } from '../google-tag-manager/google-tag-manager.service';
import { Router } from '@angular/router';
import { CrmService } from '../crm/crm.service';
import {
  CustomerCategoryEnum,
  CustomerCategoryService
} from '../customer-category/customer-category.service';

@Injectable()
export class PurchaseOrdersService {
  public api_url = '/api/shopping-cart/';

  public publicationTotals: { [publication_id: string]: number } = {};

  private minAmountExceeded: { [publication_id: string]: boolean } = {};

  totalValueExceedsMinAmount: Subject<number> = new Subject<number>();

  subject: Subject<BuyPromise[]> = new Subject();

  subjectHasError: Subject<boolean> = new Subject();

  _purchaseOrders: any[] = [];

  purchaseOrdersBPs: BuyPromise[] = [];

  _confirmedBuyPromises: BuyPromise[] = [];

  originalConfirmedBuyPromises: BuyPromise[] = [];

  isDraftOrder = false;

  readonly = false;

  isSubmittingChanges = false;

  overstock: Observable<number[]> = observableOf([]);

  subjectHasChange: Subject<boolean> = new Subject();

  _hasChange: boolean;

  get hasChange(): Observable<boolean> {
    return this.subjectHasChange.asObservable();
  }

  get buyPromises(): Observable<BuyPromise[]> {
    return this.subject.asObservable();
  }

  get hasError(): Observable<boolean> {
    return this.subjectHasError.asObservable();
  }

  constructor(
    private router: Router,
    private customerCategoryService: CustomerCategoryService,
    private crmService: CrmService,
    public storageService: StorageService,
    public gtmService: GoogleTagManagerService
  ) {}

  processPurchaseOrders(purchaseOrders: PurchaseOrder[]): void {
    this._purchaseOrders = purchaseOrders;
    const confirmedBPs = <BuyPromise[]>purchaseOrders.reduce((acc, parent) => {
      return acc.concat(parent.items);
    }, []);
    this.purchaseOrdersBPs = confirmedBPs.map(x => ({ ...x }));
  }

  /**
   * Add a product to the shopping cart
   * or update the quantity if the product already exists
   * @param buyPromise
   * @param setOriginal
   */
  addOrUpdate(buyPromise: BuyPromise, setOriginal = false): void {
    const index: number = this._confirmedBuyPromises.findIndex(
      bp => bp.product.id === buyPromise.product.id
    );
    const publication_id = buyPromise.publication_id
      ? buyPromise.publication_id
      : buyPromise.product.publication_impo;
    // Initialize minAmountExceeded for new publication_id
    if (!(publication_id in this.minAmountExceeded)) {
      this.minAmountExceeded[publication_id] = false;
    }
    if (index !== -1) {
      this._confirmedBuyPromises[index].quantity = buyPromise.quantity;
      if (
        setOriginal &&
        this._confirmedBuyPromises[index].original_quantity !==
          buyPromise.quantity
      ) {
        this._confirmedBuyPromises[index].original_quantity =
          buyPromise.quantity;
        this.crmService.updateShoppingCartStatus(
          this.getNonZeroBuyPromises(),
          buyPromise
        );
      } else {
        this._confirmedBuyPromises[index].original_quantity =
          buyPromise.original_quantity;
      }
    } else {
      this._confirmedBuyPromises.push(buyPromise);
    }

    this.checkIfHasChanges();
    this.subject.next({ ...this._confirmedBuyPromises });
    // We check if the total value exceeds the minimum amount required for the category plus
    if (
      this.customerCategoryService.customerCategory ===
        CustomerCategoryEnum.PLUS &&
      this.customerCategoryService.minAmountForPlus != 0
    ) {
      const totalValue = this.getTotalPriceByPublicationID(publication_id);
      this.publicationTotals[publication_id] = totalValue;

      if (totalValue >= this.customerCategoryService.minAmountForPlus) {
        if (this.minAmountExceeded[publication_id] === false) {
          this.minAmountExceeded[publication_id] = true;
          this.totalValueExceedsMinAmount.next(publication_id);
        }
      } else {
        if (this.minAmountExceeded[publication_id] === true) {
          this.minAmountExceeded[publication_id] = false;
          this.totalValueExceedsMinAmount.next(publication_id);
        }
      }
    }
  }

  getTotalPriceByPublicationID(publication_id: number): number {
    let total = 0;
    for (const bp of this.getNonZeroBuyPromises()) {
      if (
        bp.publication_id === publication_id ||
        bp.product.publication_impo === publication_id
      ) {
        total += this.getTotalPriceOfBuyPromise(bp);
      }
    }
    return total;
  }

  checkIfHasChanges(): void {
    this._hasChange = false;
    if (this.originalConfirmedBuyPromises) {
      if (
        this.originalConfirmedBuyPromises.length !==
        this._confirmedBuyPromises.length
      ) {
        this._hasChange = true;
      }
      for (const originBp of this.originalConfirmedBuyPromises) {
        const index: number = this._confirmedBuyPromises.findIndex(
          bp => bp.product.id === originBp.product.id
        );
        if (
          index !== -1 &&
          this._confirmedBuyPromises[index].quantity !== originBp.quantity
        ) {
          this._hasChange = true;
        } else if (index === -1) {
          this._hasChange = true;
        }
      }
    }
    this.subjectHasChange.next(this._hasChange);
  }

  /**
   * Compare the current edition of purchase order with the original one
   * and return a list of changes as BuyPromise objects.
   * @returns {BuyPromise[]}
   */
  getCartChanges(): BuyPromise[] {
    const changes: BuyPromise[] = [];
    const originalMap = new Map<number, number>();
    this.originalConfirmedBuyPromises.forEach(bp => {
      originalMap.set(bp.product.id, bp.quantity);
    });
    this._confirmedBuyPromises.forEach(bp => {
      const originalQuantity = originalMap.get(bp.product.id) || 0;
      if (bp.quantity !== originalQuantity) {
        const bpNew = new BuyPromise();
        bpNew.publication_id = bp.publication_id
          ? bp.publication_id
          : bp.product.publication_impo;
        bpNew.product_id = bp.product.id;
        bpNew.quantity = bp.quantity;
        changes.push(bpNew);
      }
      originalMap.delete(bp.product.id);
    });
    originalMap.forEach((quantity, productId) => {
      const bpNew = new BuyPromise();
      const originalBp = this.originalConfirmedBuyPromises.find(
        bp => bp.product.id === productId
      );
      bpNew.publication_id = originalBp?.publication_id
        ? originalBp.publication_id
        : originalBp?.product.publication_impo;
      bpNew.product_id = productId;
      bpNew.quantity = 0;
      changes.push(bpNew);
    });
    return changes;
  }

  getOriginalQuantity(id: number) {
    if (this.originalConfirmedBuyPromises) {
      const bp = this.originalConfirmedBuyPromises.find(
        x => x.product.id === id
      );
      return bp?.quantity ?? 0;
    }
    return 0;
  }

  /** Check if there is a product in overstock.
   *  In that case we must ask for confirmation
   */
  InOverstock(): Observable<boolean> {
    return this.overstock.pipe(map(list => list.length !== 0));
  }

  /** Add container to the list of containers with overstock */
  setContainerInOverstock(container_id: number): void {
    this.overstock.subscribe(list => {
      const index = list.indexOf(container_id);
      if (index === -1) {
        list.push(container_id);
      }
    });
  }

  /** Remove container from list of overstock */
  removeContainerOverstock(container_id: number): void {
    this.overstock.subscribe(list => {
      const index = list.indexOf(container_id);
      if (index >= 0) {
        list.splice(index, 1);
      }
    });
  }

  /**
   * Get All Confirmed Cart BuyPromises
   * @returns {BuyPromise[]}
   */
  getPurchaseOrdersItems(): BuyPromise[] | [] {
    if (this.purchaseOrdersBPs && this.purchaseOrdersBPs.length) {
      return this.purchaseOrdersBPs.filter(x => x.original_quantity !== 0);
    }
    return [];
  }

  /**
   * Get BuyPromises with quantities other than zero.
   * @returns {BuyPromise[]}
   */
  getNonZeroBuyPromises(): BuyPromise[] {
    return this._confirmedBuyPromises.filter(x => x.quantity !== 0);
  }

  getCostingCeg(container: Container): Costing {
    return container.costings.find(costing => costing.costing_type === 'ceg');
  }

  /**
   * Get the range by the selected quantity for product.
   * @param {product} Product to search range.
   * @returns{range} Range.
   */
  getRangeSelected(buyPromise: BuyPromise): Range {
    const costingCeg: Costing = this.getCostingCeg(buyPromise.product);
    return costingCeg.ranges.find(
      (range: Range) =>
        range.min_units <= buyPromise.quantity &&
        (buyPromise.quantity <= range.max_units || !range.max_units)
    );
  }

  /**
   * Get the range by the selected quantity for product.
   * Taking into account the benefits of the customer category.
   * @param {product} Product to search range.
   * @returns{range} Range.
   */
  getRangeSelectedWithBenefits(buyPromise: BuyPromise, ranges: Range[]): Range {
    let range;
    if (
      this.customerCategoryService.customerCategory ===
        CustomerCategoryEnum.PLUS &&
      this.customerCategoryService.minAmountForPlus === 0
    ) {
      range = ranges.find((range: Range) => range.best_deal) || ranges[0];
    } else if (
      this.customerCategoryService.customerCategory ===
        CustomerCategoryEnum.PLUS &&
      this.customerCategoryService.minAmountForPlus > 0
    ) {
      const totalValue =
        this.publicationTotals[
          buyPromise.publication_id
            ? buyPromise.publication_id
            : buyPromise.product.publication_impo
        ];
      if (
        this.customerCategoryService.customerCategory ===
          CustomerCategoryEnum.PLUS &&
        totalValue >= this.customerCategoryService.minAmountForPlus
      ) {
        range = ranges.find((range: Range) => range.best_deal) || ranges[0];
      } else {
        range = ranges.find(
          (range: Range) =>
            range.min_units <= buyPromise.quantity &&
            (buyPromise.quantity <= range.max_units || !range.max_units)
        );
      }
    } else {
      range = ranges.find(
        (range: Range) =>
          range.min_units <= buyPromise.quantity &&
          (buyPromise.quantity <= range.max_units || !range.max_units)
      );
    }
    return range;
  }

  /**
   * Get net total for products in shopping cart.
   * @returns{netTotal} number.
   */
  getNetTotal() {
    let netTotal = 0;
    for (const bp of this.getNonZeroBuyPromises()) {
      const costingCeg: Costing = this.getCostingCeg(bp.product);
      const rangeSelected = this.getRangeSelectedWithBenefits(
        bp,
        costingCeg.ranges
      );
      let net = 0;
      if (rangeSelected) {
        net = Number(rangeSelected.net);
        const unitPrice = net * bp.quantity;
        netTotal += unitPrice;
      }
    }
    return netTotal;
  }

  /**
   * Get total taxes for products in shopping cart.
   * @returns{totalTaxes} number.
   */
  getTotalTaxes() {
    let totalTaxes = 0;
    for (const bp of this.getNonZeroBuyPromises()) {
      const costingCeg: Costing = this.getCostingCeg(bp.product);
      const rangeSelected = this.getRangeSelectedWithBenefits(
        bp,
        costingCeg.ranges
      );
      let tax = 0;
      if (rangeSelected) {
        tax = Number(rangeSelected.tax);
        const unitPrice = tax * bp.quantity;
        totalTaxes += unitPrice;
      }
    }
    return totalTaxes;
  }

  /**
   * Get total price for products in shopping cart.
   * @returns{total} number.
   */
  getTotalPrice() {
    return Number(this.getNetTotal()) + Number(this.getTotalTaxes());
  }

  getTotalPriceOfBuyPromise(buyPromise: BuyPromise) {
    const rangeSelected = this.getRangeSelected(buyPromise);
    let net = 0;
    let tax = 0;
    if (rangeSelected) {
      net = Number(rangeSelected.net);
      tax = Number(rangeSelected.tax);
    }
    return (net + tax) * buyPromise.quantity;
  }

  goToPurchaseOrderList(purchaseOrderId = null) {
    this.router.navigate(['/intranet', 'impo', 'confirmed-order'], {
      state: { purchaseOrderId }
    });
  }
}
