import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import urlJoin from 'url-join';

export enum CustomerCategoryEnum {
  PLUS = 'Plus',
  PREMIUM = 'Premium'
}

@Injectable({
  providedIn: 'root'
})
export class CustomerCategoryService {
  static api_base_url = '/api/';
  customerCategory: string;
  minAmountForPlus: number; // Minimum amount to be considered as Plus or above

  constructor(private http: HttpClient) {}

  getCustomerCategoryUrl(companyId: number): string {
    return urlJoin(
      (<any>window).env.endPointBackend,
      CustomerCategoryService.api_base_url,
      `backoffice/company/${companyId}/category/`
    );
  }

  getCustomerCategory(companyId: number): Observable<any> {
    return this.http.get<any>(this.getCustomerCategoryUrl(companyId));
  }
}
